import React from "react"
import PropTypes from "prop-types"

const Hero = ({ description, url, alt, title, subtitle, backgroundUrl }) => {
  const backgroundStyle = {
    backgroundImage: `url('${backgroundUrl}')`,
    backgroundSize: "auto 130%",
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
  }
  return (
    <section
      style={backgroundUrl ? backgroundStyle : undefined}
      className="bg-blue-100"
    >
      <div className="flex flex-row lg:flex-nowrap flex-wrap gap-5 relative py-5 container items-center justify-between">
        <div className="max-w-md">
          <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
          <hr className="w-12 bg-transparent border-t-2 border-purple-600 mt-4 mb-6" />
          {subtitle && <h3 dangerouslySetInnerHTML={{ __html: subtitle }}></h3>}
          {subtitle ? (
            <p
              className="mt-5"
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
          ) : (
            <p
              className="mt-5"
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
          )}
        </div>
        <div className="lg:h-96 lg:max-w-lg lg:w-auto w-4/6 object-contain m-auto lg:mr-0 lg:ml-auto">
          {!backgroundUrl && (
            <img
              alt={alt}
              width="500"
              height="500"
              className="relative h-full max-h-85 lg:max-w-lg lg:w-auto object-contain m-auto lg:mr-0 lg:ml-auto"
              src={url}
            ></img>
          )}
        </div>
      </div>
    </section>
  )
}

Hero.propTypes = {
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  backgroundUrl: PropTypes.string,
}

export default Hero

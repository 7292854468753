import React from "react"
import { Link } from "gatsby"

export default function ProjectCard({ project }) {
  //Remove HTML tags from string
  const title = project.title.replace(/(<([^>]+)>)/gi, "")
  return (
    <Link className="disable-underline h-full" to={`/Projects/${project.slug}`}>
      <div className="flex flex-col h-full rounded border-gray-100 bg-gray-50 group hover:-translate-y-1 hover:shadow-xl group transform transition-all cursor-pointer">
        <img
          alt={title}
          src={`https://storage.googleapis.com/keshif-website/Projects/${project.slug}/Brief_Card.png`}
        />
        <div className="flex-grow px-4 py-5 sm:p-4 flex flex-col justify-start min-h-36">
          <p className="subtitle font-light uppercase h-8">
            {project.opendata ? "Open Data Project" : project?.client?.name}
          </p>
          <h4 className="group-hover:text-purple-600">{title}</h4>
          <div className="flex flex-col justify-end mt-3 text-xs text-gray-500 flex-grow">
            <span>{project.solutions.join(", ")}</span>
          </div>
        </div>
      </div>
    </Link>
  )
}
